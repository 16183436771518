import React, { useEffect, useState } from "react";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "interceptor/axiosInstance";

function LotteryResult() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [lotteryData, setLotteryData] = useState([]);

  useEffect(() => {
    // Fetch data from API
    axiosInstance
      .get(`${API_URL}/lottery/card/winning/`)
      .then((response) => {
        // Set the fetched data to state
        setLotteryData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the lottery data:", error);
      });
  }, []);

  // Inline Styles
  const containerStyle = {
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  };

  const tableContainerStyle = {
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "0",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
  };

  const cellStyle = {
    padding: "10px",
    textAlign: "left",
    border: "1px solid #ddd",
  };

  const responsiveStyle = {
    "@media (max-width: 768px)": {
      table: {
        display: "block",
        width: "100%",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
      },
      th: {
        padding: "8px",
      },
      td: {
        padding: "8px",
      },
    },
    "@media (max-width: 480px)": {
      th: {
        fontSize: "14px",
      },
      td: {
        padding: "6px",
      },
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={containerStyle}>
        <h2>Lottery Results</h2>
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Lottery Card Name</th>
                <th style={tableHeaderStyle}>Lottery Card Number</th>
                <th style={tableHeaderStyle}>Date Of Winning</th>
                <th style={tableHeaderStyle}>Result Date</th>
                <th style={tableHeaderStyle}>Last Update</th>
              </tr>
            </thead>
            <tbody>
              {lotteryData.map((lottery) => (
                <tr key={lottery.id}>
                  <td style={cellStyle}>{lottery.lottery_card_name}</td>
                  <td style={cellStyle}>{lottery.lottery_card_number || "N/A"}</td>
                  <td style={cellStyle}>{lottery.lottery_date || "N/A"}</td>
                  <td style={cellStyle}>{new Date(lottery.create_at).toLocaleString()}</td>
                  <td style={cellStyle}>{new Date(lottery.update_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default LotteryResult;
