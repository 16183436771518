import React, { useEffect, useState } from "react";
import axiosInstance from "interceptor/axiosInstance";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function UpdateTime() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [timers, setTimers] = useState([]);
  const [selectedTimer, setSelectedTimer] = useState(null);
  const [updatedData, setUpdatedData] = useState({ start_time: "", end_time: "" });
  const [newTimerData, setNewTimerData] = useState({
    start_time: "",
    end_time: "",
    time_interval: "",
  });
  const [showModal, setShowModal] = useState(false);

  // Fetch timers
  useEffect(() => {
    fetchTimers();
  }, []);

  const fetchTimers = () => {
    axiosInstance
      .get(`${API_URL}/lottery/card/timer/list/`)
      .then((response) => setTimers(response.data))
      .catch((error) => console.error("Error fetching timers:", error));
  };

  // Handle input changes
  const handleInputChange = (e, setData) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // Open "Update Timer" modal and close "Create New Lottery Time" modal
  const handleUpdateClick = (timer) => {
    setSelectedTimer(timer);
    setUpdatedData({
      start_time: new Date(timer.start_time).toISOString().slice(0, 16), // Format for datetime-local
      end_time: new Date(timer.end_time).toISOString().slice(0, 16), // Format for datetime-local
    });
    setShowModal(false); // Close the "Create New Lottery Time" modal if open
  };

  // Open "Create New Lottery Time" modal and close "Update Timer" modal
  const handleCreateClick = () => {
    setShowModal(true);
    setSelectedTimer(null); // Close the "Update Timer" modal if open
  };

  // Submit updated timer
  const handleSubmit = () => {
    if (!selectedTimer) return;
    axiosInstance
      .put(`${API_URL}/lottery/card/timer/update/${selectedTimer.id}/`, updatedData)
      .then(() => {
        alert("Timer updated successfully!");
        setSelectedTimer(null);
        setUpdatedData({ start_time: "", end_time: "" });
        fetchTimers();
      })
      .catch((error) => console.error("Error updating timer:", error));
  };

  // Submit new timer
  const handleNewTimerSubmit = () => {
    axiosInstance
      .post(`${API_URL}/lottery/card/timer/create/`, newTimerData)
      .then(() => {
        alert("New lottery time created successfully!");
        setNewTimerData({ start_time: "", end_time: "", time_interval: "" });
        setShowModal(false);
        fetchTimers();
      })
      .catch((error) => {
        console.error("Error creating new timer:", error);
        alert("Failed to create new lottery time. Please try again.");
      });
  };

  const renderTableRows = () =>
    timers.map((timer) => {
      const startTime = new Date(timer.start_time).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      const endTime = new Date(timer.end_time).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      return (
        <tr key={timer.id}>
          <td style={cellStyle}>{startTime}</td>
          <td style={cellStyle}>{endTime}</td>
          <td style={cellStyle}>
            <button
              onClick={() => handleUpdateClick(timer)}
              style={buttonStyle}
              onMouseEnter={(e) => handleHover(e, true)}
              onMouseLeave={(e) => handleHover(e, false)}
            >
              <span style={{ marginRight: "8px", fontSize: "1.2em" }}>✏️</span> Update
            </button>
          </td>
        </tr>
      );
    });

  const renderModal = (isUpdate) => (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <h3>{isUpdate ? "Update Timer" : "Create New Lottery Time"}</h3>
        <form>
          <div>
            <label>Start Time</label>
            <input
              type="datetime-local"
              name="start_time"
              value={newTimerData.start_time || updatedData.start_time}
              onChange={(e) => handleInputChange(e, isUpdate ? setUpdatedData : setNewTimerData)}
              required
              style={inputStyle}
            />
          </div>
          <div>
            <label>End Time</label>
            <input
              type="datetime-local"
              name="end_time"
              value={newTimerData.end_time || updatedData.end_time}
              onChange={(e) => handleInputChange(e, isUpdate ? setUpdatedData : setNewTimerData)}
              required
              style={inputStyle}
            />
          </div>
          {!isUpdate && (
            <div>
              <label>Time Interval</label>
              <input
                type="number"
                name="time_interval"
                value={newTimerData.time_interval}
                onChange={(e) => handleInputChange(e, setNewTimerData)}
                placeholder="Enter interval in seconds"
                required
                style={inputStyle}
              />
            </div>
          )}
          <div style={modalFooterStyle}>
            <button
              type="button"
              onClick={() => (isUpdate ? setSelectedTimer(null) : setShowModal(false))}
              style={closeButtonStyle}
            >
              Close
            </button>
            <button
              type="button"
              onClick={isUpdate ? handleSubmit : handleNewTimerSubmit}
              style={submitButtonStyle}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const handleHover = (e, isHover) => {
    e.target.style.transform = isHover ? "scale(1.05)" : "scale(1)";
    e.target.style.boxShadow = isHover ? "0 4px 10px rgba(0, 0, 0, 0.2)" : "none";
  };

  // Inline styles
  const containerStyle = { width: "100%", padding: "20px", boxSizing: "border-box" };
  const tableContainerStyle = { overflowX: "auto", WebkitOverflowScrolling: "touch" };
  const tableStyle = { width: "100%", borderCollapse: "collapse" };
  const tableHeaderStyle = { backgroundColor: "#f4f4f4", fontWeight: "bold" };
  const cellStyle = { padding: "10px", textAlign: "left", border: "1px solid #ddd" };
  const buttonStyle = {
    background: "linear-gradient(90deg, #007bff, #0056b3)",
    color: "white",
    borderRadius: "4px",
    padding: "6px 12px",
    cursor: "pointer",
    fontWeight: "bold",
  };
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };
  const modalContentStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
  };
  const inputStyle = { display: "block", margin: "10px 0", width: "100%" };
  const modalFooterStyle = { display: "flex", justifyContent: "flex-end", gap: "10px" };
  const closeButtonStyle = {
    padding: "10px 15px",
    background: "#f5f5f5",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };
  const submitButtonStyle = {
    padding: "10px 15px",
    background: "linear-gradient(90deg, #28a745, #218838)",
    color: "white",
    borderRadius: "4px",
    border: "none",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={containerStyle}>
        <h2>Update Timers</h2>
        <div style={tableContainerStyle}>
          <button
            onClick={handleCreateClick}
            style={buttonStyle}
            onMouseEnter={(e) => handleHover(e, true)}
            onMouseLeave={(e) => handleHover(e, false)}
          >
            <span style={{ marginRight: "8px", fontSize: "1.2em" }}>✏️</span> New Lottery Time
          </button>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Start Time</th>
                <th style={tableHeaderStyle}>End Time</th>
                <th style={tableHeaderStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
        {showModal && renderModal(false)}
        {selectedTimer && renderModal(true)}
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateTime;
