import React, { useEffect, useState } from "react";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "interceptor/axiosInstance";

function TotalUserList() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [updatedAmount, setUpdatedAmount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Debounce search term to limit API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Fetch user list data
  useEffect(() => {
    axiosInstance
      .get(`${API_URL}/account/user/list/`, {
        params: { search: debouncedSearch, page: currentPage },
      })
      .then((response) => {
        setUserData(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 users per page
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [debouncedSearch, currentPage]);

  // Handle Update
  const handleUpdate = () => {
    if (!selectedUser || !updatedAmount) {
      alert("Please select a user and enter a new amount.");
      return;
    }

    axiosInstance
      .put(`${API_URL}/account/update/pricing/${selectedUser.id}/`, {
        amount: updatedAmount,
      })
      .then(() => {
        alert("Price updated successfully!");

        // Fetch the updated user data
        return axiosInstance.get(`${API_URL}/account/update/pricing/${selectedUser.id}/`);
      })
      .then((response) => {
        const updatedUser = response.data;

        // Update user data in the state
        setUserData((prevUserData) =>
          prevUserData.map((user) =>
            user.id === updatedUser.id ? { ...user, amount: updatedUser.amount } : user
          )
        );

        setSelectedUser(null);
        setUpdatedAmount("");
      })
      .catch((error) => {
        console.error("Error updating price or fetching updated data:", error);
      });
  };

  const handleOpenUpdateModal = (user) => {
    setSelectedUser(user);
    setUpdatedAmount(user.amount); // Pre-fill the modal with the current price
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Inline Styles
  const containerStyle = {
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  };

  const tableContainerStyle = {
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "0",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
  };

  const cellStyle = {
    padding: "10px",
    textAlign: "left",
    border: "1px solid #ddd",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={containerStyle}>
        <h2>User List</h2>
        <input
          type="text"
          placeholder="Search by name, email, or code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            marginBottom: "10px",
            padding: "10px",
            width: "100%",
            boxSizing: "border-box",
            border: "1px solid #ddd",
            borderRadius: "5px",
            fontSize: "16px",
            transition: "border-color 0.3s ease, box-shadow 0.3s ease",
          }}
          onFocus={(e) => {
            e.target.style.borderColor = "#007bff";
            e.target.style.boxShadow = "0 0 5px rgba(0, 123, 255, 0.5)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#ddd";
            e.target.style.boxShadow = "none";
          }}
        />
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Unique Code</th>
                <th style={tableHeaderStyle}>Referral Code</th>
                <th style={tableHeaderStyle}>Paid 500</th>
                <th style={tableHeaderStyle}>Paid 3100</th>
                <th style={tableHeaderStyle}>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((userVar) => (
                <tr key={userVar.id}>
                  <td style={cellStyle}>{userVar.first_name}</td>
                  <td style={cellStyle}>{userVar.email || "N/A"}</td>
                  <td style={cellStyle}>{userVar.amount}</td>
                  <td style={cellStyle}>{userVar.unique_code || "N/A"}</td>
                  <td style={cellStyle}>{userVar.referral_code || "N/A"}</td>
                  <td style={cellStyle}>{userVar.is_500_amount ? "Yes" : "No"}</td>
                  <td style={cellStyle}>{userVar.is_3100_amount ? "Yes" : "No"}</td>
                  <td style={cellStyle}>
                    <button
                      onClick={() => handleOpenUpdateModal(userVar)}
                      style={{
                        background: "linear-gradient(90deg, #007bff, #0056b3)",
                        border: "none",
                        color: "white",
                        fontWeight: "bold",
                        padding: "6px 12px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              backgroundColor: currentPage === 1 ? "#ccc" : "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: currentPage === 1 ? "not-allowed" : "pointer",
            }}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            style={{
              marginLeft: "10px",
              padding: "10px 20px",
              backgroundColor: currentPage === totalPages ? "#ccc" : "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: currentPage === totalPages ? "not-allowed" : "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>
      {/* Update Modal */}
      {selectedUser && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              textAlign: "center",
            }}
          >
            <h3>Update Price</h3>
            <p>
              Update price for <strong>{selectedUser.first_name}</strong>
            </p>
            <p>
              Current Price: <strong>{selectedUser.amount}</strong>
            </p>
            <input
              type="number"
              placeholder="Enter new amount"
              value={updatedAmount}
              onChange={(e) => setUpdatedAmount(e.target.value)}
              style={{
                padding: "8px",
                marginBottom: "10px",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
            <button
              onClick={handleUpdate}
              style={{
                background: "green",
                color: "white",
                border: "none",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
            <button
              onClick={() => setSelectedUser(null)}
              style={{
                background: "red",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default TotalUserList;
