import axios from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(email, password) {
    try {
      // Data to send in the POST request
      const data = {
        email,
        password,
      };

      // Send a POST request to create a JWT token
      const response = await axios.post(`${API_URL}/auth/jwt/create/`, data);

      if (response.status !== 200) {
        toast.error("Please Login Again");
      }
      if (response.data) {
        const responseData = response.data;

        localStorage.setItem("token", responseData?.access);
        return responseData;
      }
    } catch (error) {
      if (error.response.data.detail) {
        toast.error(`${error.response.data.detail}`);
      }
      // else if (error.response.data.email) {
      //   toast.error(`${error.response.data.email}`)
      // }
      // alert(error)
      // toast.error("your token has been expired, Please Login");
      // window.location.href = "/login";
    }
  }

  // Add other authentication-related methods here.
}

export default new AuthService();
